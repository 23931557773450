@import "fonts.scss";
@import "variables.scss";
@import "scss-config.scss";
@import "/node_modules/purple-lib/styles/purple-bootstrap.scss";
@import "/node_modules/purple-lib/styles/variable.scss";


html {
  font-family: $text-font, sans-serif !important;
  background-color: $background-color;
  overflow-x: hidden;
  overflow-y: hidden;
  height: --webkit-fill-available;
}

body {
  height: 100vh;
  //height: var(--app-height);
  height: 100%;
  background-color: $background-color;
}

//Scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow      : inset 0 0 5px $scrollbar-background-shadow-color;
  background-color: $scrollbar-background-color;
}

::-webkit-scrollbar-thumb {
  background           : $scrollbar-color;
  -webkit-border-radius: 20px;
  border-radius        : 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

/* .ps-modal {

  &.footer-center {
    & .ant-modal-footer {
      display        : flex;
      justify-content: center;
    }
  }

  & .ant-modal-content {
    //border-radius: 14px !important;
  }

  & .ant-modal-header {
    border-top-left-radius : 14px !important;
    border-top-right-radius: 14px !important;
  }

  & .ant-modal-title {
    color      : $primary-color;
    font-size  : 22px;
    font-weight: 600;
  }

  & .ant-modal-confirm-content {
    font-size: 16px;
  }

  & .ant-modal-confirm-body {
    & .anticon {

      margin-top: 5px;
    }
  }

  & .ant-modal-confirm-title {
    color      : $primary-color;
    font-size  : 22px;
    font-weight: 600;
  }

  & .ant-modal-footer {
    & .ant-btn {
      box-shadow   : 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      min-width    : 60px;
      height       : 45px;
      font-size    : 16px;
      font-weight  : 600;
      border-color : transparent;

      & span {
        font-size: 16px;
      }
    }


  }

  & .ant-modal-confirm-btns {
    & .ant-btn {
      box-shadow   : 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      min-width    : 60px;
      height       : 45px;
      font-size    : 16px;
      font-weight  : 600;
      border-color : transparent;

      & span {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 450px) {

  .ps-modal {
    & .ant-modal-content {
      //border-radius: 14px !important;
      padding: 24px 15px;

      & .ant-modal-header {
        border-top-left-radius : 14px !important;
        border-top-right-radius: 14px !important;
        text-align             : center;
        border-bottom          : none;
        padding                : 0;

        & .ant-modal-title {
          color      : $primary-color;
          font-size  : 22px;
          font-weight: 600;
        }
      }

      & .ant-modal-body{
        padding: 16px 0px;
        font-size: 14px;
        text-align: center;
      }

      & .ant-modal-footer {
        display        : flex;
        justify-content: center;
        border-top: none;

        & .ant-btn {
          border-radius: 10px;
          min-width    : 60px;
          height       : 45px;
          font-size    : 16px;
          font-weight  : 600;
          box-shadow: none;
          border-color : rgba(0, 0, 0, 0.85);

          &:focus{
            border-color : $primary-color;

            &.ant-btn-primary{
              border-color : transparent;
            }
          }

          &.ant-btn-primary{
            border-color : transparent;
            margin-left: 20px;
          }

          & span {
            font-size: 14px;
          }
        }


      }
    }


    & .ant-modal-confirm-content {
      font-size: 16px;
    }

    & .ant-modal-confirm-body {
      & .anticon {

        margin-top: 5px;
      }
    }

    & .ant-modal-confirm-title {
      color      : $primary-color;
      font-size  : 22px;
      font-weight: 600;
    }



    & .ant-modal-confirm-btns {
      & .ant-btn {
        box-shadow   : 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        min-width    : 60px;
        height       : 45px;
        font-size    : 16px;
        font-weight  : 600;
        border-color : transparent;

        & span {
          font-size: 16px;
        }
      }
    }
  }

} */

.ps-modal {
  &.full-button{
    & .ant-modal-footer {
      & .ant-btn {
        padding: 9px 55px !important;
        margin: 0 0px !important;
      }
    }
  }

  & .ant-modal-content {
    //border-radius: 14px !important;
    padding: 30px 15px 24px 15px;

    & .ant-modal-header {
      text-align: center;
      border-bottom: none;
      padding: 0;

      & .ant-modal-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 22px;
        font-weight: 600;
        //text-transform: uppercase;
        letter-spacing: 0.3px;
      }
    }

    & .ant-modal-body {
      padding: 28px 0 30px 0;
      font-size: 16px;
      font-weight: 300;
      text-align: center;
    }

    

    & .ant-modal-footer {
      display: flex;
      justify-content: center;
      border-top: none;

      & .ant-btn {
        border-radius: 0px !important;
        min-width: 60px;
        height: auto;
        font-size: 14px;
        font-weight: 600;
        box-shadow: none;
        border-color : $primary-color !important;
        text-transform: uppercase;
        padding: 10px 30px;
        margin: 0 7px;

        &.ant-btn-primary {
          background-color: $primary-color;
          color: #ffffff;
        }

        &:focus {
          border-color: $primary-color;

          &.ant-btn-primary {
            border-color: transparent;
          }
        }

        & span {
          font-size: 14px;
        }
      }
    }
  }

  & .ant-modal-confirm-content {
    font-size: 16px;
  }

  & .ant-modal-confirm-body {
    & .anticon {
      margin-top: 5px;
    }
  }

  & .ant-modal-confirm-title {
    color: $primary-color;
    font-size: 22px;
    font-weight: 600;
  }

  & .ant-modal-confirm-btns {
    & .ant-btn {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      min-width: 60px;
      height: 45px;
      font-size: 16px;
      font-weight: 600;
      border-color: transparent;

      & span {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .ps-modal {

    &.full-button{
      & .ant-modal-footer {
        & .ant-btn {
          padding: 9px 55px !important;
          margin: 0 0px !important;
        }
      }
    }

    & .ant-modal-content {
      //border-radius: 14px !important;
      padding: 24px 15px;

      & .ant-modal-header {
        text-align: center;
        border-bottom: none;
        padding: 0;

        & .ant-modal-title {
          color: rgba(0, 0, 0, 0.85);
          font-size: 22px;
          font-weight: 700;
        }
      }

      & .ant-modal-body {
        padding: 16px 0 18px 0;
        font-size: 14px;
        font-weight: 300;
        text-align: center;
      }

      

      & .ant-modal-footer {
        display: flex;
        justify-content: center;
        border-top: none;

        & .ant-btn {
          border-radius: 0px !important;
          min-width: 60px;
          height: auto;
          font-size: 12px;
          font-weight: 600;
          box-shadow: none;
          //border-color : #000000 !important;
          //background-color: #000000;
          //color: #ffffff;
          text-transform: uppercase;
          //padding: 9px 25px;
          padding: 9px 0px;
          margin: 0 7px;
          width: 100%;
          display: flex;
          justify-content: center;

          /* &.ant-btn-primary {
            background-color: #000000;
            color: #ffffff;
          }

          &:focus {
            border-color: #000000;

            &.ant-btn-primary {
              border-color: transparent;
            }
          } */

          & span {
            font-size: 14px;
          }
        }
      }
    }

    & .ant-modal-confirm-content {
      font-size: 16px;
    }

    & .ant-modal-confirm-body {
      & .anticon {
        margin-top: 5px;
      }
    }

    & .ant-modal-confirm-title {
      color: $primary-color;
      font-size: 22px;
      font-weight: 600;
    }

    & .ant-modal-confirm-btns {
      & .ant-btn {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        min-width: 60px;
        height: 45px;
        font-size: 16px;
        font-weight: 600;
        border-color: transparent;

        & span {
          font-size: 16px;
        }
      }
    }
  }
}
